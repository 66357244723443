import React from 'react';
import './App.css';

function UserIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </svg>
  )
}

function GithubIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4" />
      <path d="M9 18c-4.51 2-5-2-7-2" />
    </svg>
  )
}


function LinkedinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
      <rect width="4" height="12" x="2" y="9" />
      <circle cx="4" cy="4" r="2" />
    </svg>
  )
}


function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  )
}


function App() {
  return (
    <div className="flex flex-col min-h-[100vh]">
      <header className="App px-4 mt-4  lg:px-6 h-14 flex flex-row justify-between">
            <div className='flex flex-row mt-2 justify-items-center'>
              <UserIcon className="h-6 w-6"/>
              <h2 className='ml-2 font-medium'>Shekar Arukala</h2>
            </div> 
            <nav className='flex flex-row justify-items-center space-x-4 mt-2'>
              <h3 className='text-sm font-medium hover:underline underline-offset-4 hover:cursor-pointer'>About</h3>
              <h3 className='text-sm font-medium hover:underline underline-offset-4 hover:cursor-pointer'>Contact</h3>
            </nav>
      </header>
      <main className='flex-1'>
      <section className="w-full pt-12 md:pt-24 lg:pt-32 border-t">
          <div className="px-4 md:px-6 space-y-10 xl:space-y-16">
            <h1 className=" lg:leading-tighter text-3xl font-semibold tracking-tighter sm:text-4xl md:text-5xl xl:text-[3.4rem] 2xl:text-[3.75rem] text-center">
              Shekar Arukala
            </h1>
            <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400 text-center">
            Passionate Full Stack Developer with expertise in Backend Technologies. Proficient in ReactJS, Spring Boot, Postgres, NestJS. Linux enthusiast and a constant learner in the evolving tech landscape
            </p>
            <div className="flex justify-center  space-x-8">
              <a className="inline-flex h-9 items-center justify-center hover:text-grey" href="https://twitter.com/shekar_dev">
                <TwitterIcon className="h-6 w-6 text-black hover:text-grey" />
              </a>
              <a className="inline-flex h-9 items-center justify-center hover:text-grey" href="https://www.linkedin.com/in/shekar-arukala/">
                <LinkedinIcon className="h-6 w-6 text-black hover:text-grey" />
              </a>
              <a className="inline-flex h-9 items-center justify-center hover:text-grey" href="https://github.com/shekargoud26">
                <GithubIcon className="h-6 w-6 text-black hover:text-grey" />
              </a>
            </div>
          </div>
        </section>
      </main>
    </div>
    
  );
}

export default App;
